import React, { useEffect, useState } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "react-hot-toast";
import { ThemeProvider, createTheme } from "@mui/material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers";

import "./assets/styles/App.css";
import AppBody from "components/UI/organisms/AppBody/AppBody";
import AppFooter from "components/UI/organisms/AppFooter/AppFooter";
import AppHeader from "components/UI/organisms/AppHeader/AppHeader";
import AuthProvider from "context/AuthContext";

let theme = createTheme({
    palette: {
        primary: { main: "#706dca" },
        secondary: { main: "#43AA8B" },
    },
    typography: {
        fontFamily: "DM Sans",
    },
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: "none",
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    width: "100%",
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    width: "100%",
                },
            },
        },
        MuiGrid: {
            styleOverrides: {
                root: {
                    marginTop: 0,
                    marginLeft: 0,
                    width: "100%",
                    paddingTop: 0,
                    paddingLeft: 0,
                },
            },
        },
    },
});

let queryClient = new QueryClient();

export default function App() {
    return (
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <AuthProvider>
                            <AppContainer />
                        </AuthProvider>
                    </LocalizationProvider>
                </ThemeProvider>
            </QueryClientProvider>
        </BrowserRouter>
    );
}

function AppContainer() {
    let { pathname } = useLocation();
    const [backgroundImage, setBackgroundImage] = useState<string | null>(null);

    const closeLoginDialog = () => {
        const closeLoginDialogEvent = new CustomEvent("closeLoginDialog");
        window.dispatchEvent(closeLoginDialogEvent);
    };

    useEffect(() => {
        const lowerCaseUrl = pathname.toLowerCase().split("/");
        if (
            lowerCaseUrl.includes("login") ||
            lowerCaseUrl.includes("register")
        ) {
            const randomImage = `https://source.unsplash.com/1200x400/?car`;
            setBackgroundImage(randomImage);
        } else {
            setBackgroundImage(null);
        }
    }, [pathname]);

    return (
        <div
            className="AppBody"
            onClick={closeLoginDialog}
            style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: "cover",
                backgroundAttachment: "fixed",
            }}
        >
            <div
                style={{
                    transition: ".3s ease-in-out",
                }}
            >
                <AppHeader />
                <AppBody />
                <AppFooter />
            </div>
            <Toaster />
        </div>
    );
}
