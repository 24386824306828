import React from "react";
import {
    faCar,
    faCarSide,
    faCarAlt,
    faCaravan,
    faTruckPickup,
    faTruckMoving,
    faShuttleVan,
    faBatteryFull,
    faRecycle,
    faGem,
    faTractor,
    faMountain,
    faMotorcycle,
    faBicycle,
    faShip,
    faLifeRing,
    IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import {
    SectionLabel,
    CarMake,
    ShopByPrice,
    OtherCategoriesCard,
    VehiclesInStockCard,
} from "components/UI/atoms/CategoryListings/CategoryListings";
import {
    AdvertData,
    CarMakesObject,
    ShopByTypeObject,
    SearchParameterOption,
} from "./AppBody";
import { ManufacturerWithCount, VehicleCategoryWithCount } from "types/shared";

export function LeftFilters({
    carManufacturers,
    shopByType,
    otherCategories,
    priceRanges,
}: {
    carManufacturers: ManufacturerWithCount[] | null;
    priceRanges: Array<[number, number]> | null;
    shopByType: VehicleCategoryWithCount[] | null;
    otherCategories: SearchParameterOption[] | null;
}) {
    const vehicleTypesIcons = [
        { name: "Sedan or Saloon car", icon: faCar },
        { name: "Sports car", icon: faCarSide },
        { name: "Convertible car", icon: faCarAlt },
        { name: "SUV or Crossover", icon: faCaravan },
        { name: "Pickup truck", icon: faTruckPickup },
        { name: "Box truck or Cargo van", icon: faTruckMoving },
        { name: "Minivan", icon: faShuttleVan },
        { name: "Electric car", icon: faBatteryFull },
        { name: "Hybrid car", icon: faRecycle },
        { name: "Luxury car", icon: faGem },
        { name: "Coupe", icon: faCarAlt },
        { name: "Hatchback", icon: faCarSide },
        { name: "Wagon", icon: faTractor },
        { name: "Off-road vehicle", icon: faMountain },
        { name: "Motorcycle", icon: faMotorcycle },
        { name: "Scooter", icon: faMotorcycle },
        { name: "Bicycle", icon: faBicycle },
        { name: "Boat", icon: faShip },
        { name: "Jet ski", icon: faLifeRing },
    ];

    return (
        <>
            {/* {leftAdverts && leftAdverts instanceof Array &&
                leftAdverts.map(({ advert_attachment }) => {
                    return <Advert AdvertImagePath={advert_attachment} />;
                })} */}

            <SectionLabel labelName={"SHOP BY BRAND"} />

            {carManufacturers &&
                carManufacturers instanceof Array &&
                carManufacturers.map(({ id, name, vehicle_count }) => (
                    <CarMake make={name} count={vehicle_count} id={id} />
                ))}

            <SectionLabel labelName={"SHOP BY PRICE"} />
            {priceRanges &&
                priceRanges instanceof Array &&
                priceRanges.map(([from, to]) => (
                    <ShopByPrice amountRange={`$${from} to $${to}`} />
                ))}

            <SectionLabel labelName={"SHOP BY TYPE"} />
            {shopByType &&
                shopByType instanceof Array &&
                shopByType.map(({ name: category_name, id, vehicle_count }) => {
                    let icon: IconDefinition | undefined;

                    for (let item of vehicleTypesIcons) {
                        let iconName = item.name.toLowerCase();

                        if (!icon) {
                            let matches = iconName
                                .split(" ")
                                .some((chunk) =>
                                    chunk.startsWith(
                                        category_name.toLowerCase()
                                    )
                                );
                            if (matches) {
                                icon = item.icon;
                            }
                        }

                        if (icon) {
                            break;
                        }
                    }

                    return (
                        <CarMake
                            id={Number(id)}
                            icon={icon as IconProp}
                            make={category_name}
                            count={Number(vehicle_count)}
                            defaultMake={"toyota"}
                        />
                    );
                })}

            <SectionLabel labelName={"OTHER CATEGORIES"} />
            {otherCategories &&
                otherCategories instanceof Array &&
                otherCategories.map(({ name }) => (
                    <OtherCategoriesCard
                        categoryName={name}
                        id={name.toLowerCase()}
                    />
                ))}

            {/* <SectionLabel labelName={"AVAILABLE COUNTRIES"} />
            {vehiclesInStock && vehiclesInStock instanceof Array &&
                vehiclesInStock.map((country) => (
                    <VehiclesInStockCard country={country} />
                ))} */}
        </>
    );
}
