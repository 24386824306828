import React, { ReactNode } from "react";

import { Advert } from "../../molecules/advert/Advert";
import { FacebookPage } from "components/UI/atoms/FacebookPage";
import Advert5 from "../../../../assets/images/advert5.jpg";
import { LeftFilters } from "./LeftFilters";
import {
    AdvertData,
    CarMakesObject,
    ShopByTypeObject,
    SearchParameterOption,
    withSuspense,
    AvailableCountry,
} from "./AppBody";
import { ManufacturerWithCount, VehicleCategoryWithCount } from "types/shared";

export type MainPageWrapperProps = {
    children: ReactNode | ReactNode[];
    carManufacturers: ManufacturerWithCount[] | null;
    priceRanges: Array<[number, number]> | null;
    shopByType: VehicleCategoryWithCount[] | null;
    otherCategories: SearchParameterOption[] | null;
};

export function MainPageWrapper({
    children,
    carManufacturers,
    shopByType,
    otherCategories,
    priceRanges,
}: MainPageWrapperProps) {
    return (
        <div className="searchPages">
            <div className={"appMainBodyDiv"}>
                <LeftFilters
                    carManufacturers={carManufacturers}
                    priceRanges={priceRanges}
                    shopByType={shopByType}
                    otherCategories={otherCategories}
                />
            </div>

            <div className={"appMainBodyDiv"}>{withSuspense(children)}</div>

            <div className={"appMainBodyDiv"} style={{ height: "fit-content" }}>
                <FacebookPage />
            </div>
        </div>
    );
}
