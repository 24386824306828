import React, { Component } from "react";
import {
    Close,
    Facebook,
    HomeOutlined,
    Instagram,
    Login,
    MenuOutlined,
    SearchRounded,
    Twitter,
    WhatsApp,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

import "./TopHeader.scss";
import logo from "../../../../assets/images/logo.png";
import { apiRoutes } from "utils/apiConfig";
import { useApiData } from "Hooks/ApiHooks/useApiData";
import { PromotionalCategory } from "types/shared";
import { useAuthContext } from "context/AuthContext";

type TopHeaderProps = {
    totalInStock?: number;
    addedToday?: number;
    time?: string;
    contact: string;
};

type TopHeaderState = {
    sideBarOpen: boolean;
};

export default class TopHeader extends Component<
    TopHeaderProps,
    TopHeaderState
> {
    constructor(props: TopHeaderProps) {
        super(props);
        this.state = {
            sideBarOpen: false,
        };
    }

    render() {
        let html = document.querySelector("html") as HTMLHtmlElement;
        html.style.overflow = this.state.sideBarOpen ? "hidden" : "unset";

        return (
            <>
                {/* <div className={"topHeaderHeader"}>
                    <div className={"defaultContainerWrapper"}>
                        <BasicInfo
                            totalInStock={this.props.totalInStock}
                            addedToday={this.props.addedToday}
                            time={this.props.time}
                            contact={this.props.contact}
                        />
                        <SocialMediaButtons />
                    </div>
                </div> */}

                <MobileTopHeader
                    openSideBar={() =>
                        this.setState({
                            sideBarOpen: true,
                        })
                    }
                />

                <SideBar
                    sideBarOpen={this.state.sideBarOpen}
                    closeSideBar={() => {
                        this.setState({
                            sideBarOpen: false,
                        });
                    }}
                />
            </>
        );
    }
}

function MobileTopHeader({ openSideBar }: { openSideBar: () => void }) {
    let { isAuthenticated } = useAuthContext();

    return (
        <div className={"mobileTopHeaderHeader"}>
            <div className={"defaultContainerWrapper"}>
                <div className={"mobileTopHeaderSection"} onClick={openSideBar}>
                    <MenuOutlined style={{ color: "white" }} />
                    <small>Menu</small>
                </div>

                <Link to="/" className={"mobileTopHeaderSection"}>
                    <img
                        src={logo}
                        alt={"logo"}
                        className={"responsiveImage"}
                    />
                </Link>
                <Link
                    to="/mobile-search-engine"
                    className={"mobileTopHeaderSection"}
                >
                    <SearchRounded style={{ color: "white" }} />
                    <small>Search</small>
                </Link>
                {/* <Link
                    className={"mobileTopHeaderSection"}
                    to={!isAuthenticated ? "/login" : "/"}
                >
                    {!isAuthenticated ? (
                        <>
                            <Login style={{ color: "white" }} />
                            <small>Login</small>
                        </>
                    ) : (
                        <>
                            <HomeOutlined style={{ color: "white" }} />
                            <small>Home</small>
                        </>
                    )}
                </Link> */}
            </div>
        </div>
    );
}

function SocialMediaButtons() {
    return (
        <div className={"topHeaderSection"}>
            <div className={"topHeaderSocialMedia"}>
                <a href="https://wa.me/265998562786" target="_blank">
                    <WhatsApp />
                </a>
            </div>
            <div className={"topHeaderSocialMedia"}>
                <a
                    href="https://www.facebook.com/carbellmalawi?mibextid=ZbWKwL"
                    target="_blank"
                >
                    <Facebook />
                </a>
            </div>
            {/*<div className={"topHeaderSocialMedia"}>*/}
            {/*    <YouTube fontSize={'2px'} onClick={*/}
            {/*        ()=>window.open('https://twitter.com/CarbellM?t=dBdu8j4EI2GCcJg2XfK7tQ&s=0')*/}
            {/*    }/>*/}
            {/*</div>*/}
            <div className={"topHeaderSocialMedia"}>
                <a
                    href="https://twitter.com/CarbellM?t=dBdu8j4EI2GCcJg2XfK7tQ&s=0"
                    target="_blank"
                >
                    <Twitter />
                </a>
            </div>
            {/* <div className={"topHeaderSocialMedia"}>
                <a
                    href="https://www.facebook.com/carbellmalawi?mibextid=ZbWKwL"
                    target="_blank"
                >
                    <Instagram />
                </a>
            </div> */}
        </div>
    );
}

function BasicInfo({
    addedToday,
    contact,
    time,
    totalInStock,
}: {
    totalInStock?: number;
    addedToday?: number;
    time?: string;
    contact: string;
}) {
    return (
        <div className={"topHeaderSection"}>
            {totalInStock ? (
                <div className={"topHeaderInfo hideWhenPrinting"}>
                    Total Cars In Stock {totalInStock}
                </div>
            ) : null}
            {addedToday ? (
                <div className={"topHeaderInfo hideWhenPrinting"}>
                    Cars Added Today {addedToday}
                </div>
            ) : null}
            {time ? (
                <div className={"topHeaderInfo hideWhenPrinting"}>
                    Malawi Time {time}
                </div>
            ) : null}
            <div className={"topHeaderInfo"}>
                <b>Contact {contact}</b>
            </div>
        </div>
    );
}

function SideBar({
    closeSideBar,
    sideBarOpen,
}: {
    sideBarOpen: boolean;
    closeSideBar: () => void;
}) {
    let { data: promotionalCategories } = useApiData<
        PromotionalCategory[] | null
    >(apiRoutes.PROMOTIONAL_CATEGORIES, null);

    return (
        <>
            <div
                className={"appSidebarOverlay"}
                style={{
                    visibility: sideBarOpen ? "visible" : "hidden",
                }}
                onClick={closeSideBar}
            ></div>

            <div
                className={"appSideBar"}
                style={{
                    left: sideBarOpen ? "0" : "-70%",
                }}
            >
                <div className={"appSideHeader"}>
                    <Link className={"appSideBarLogo"} to="/">
                        <img
                            src={logo}
                            alt={"logo"}
                            className={"responsiveImage"}
                        />
                    </Link>
                    <div className={"appCloseButton"} onClick={closeSideBar}>
                        <Close />
                    </div>
                </div>

                <div className={"appSideBody"}>
                    <ul>
                        <Link to="/">
                            <li>Home</li>
                        </Link>
                        <Link to="/about-us">
                            <li>About Us</li>
                        </Link>
                        <Link to="/contact">
                            <li>Contact Us</li>
                        </Link>
                        {promotionalCategories &&
                            promotionalCategories instanceof Array &&
                            promotionalCategories.map((element) => (
                                <Link
                                    to={`/search?promotional_categories=${element.id}`}
                                >
                                    <li onClick={closeSideBar}>
                                        {element.name}
                                    </li>
                                </Link>
                            ))}
                    </ul>
                </div>
            </div>
        </>
    );
}
