import React, { useEffect, useState } from "react";
import {
    Search,
    Person,
    ArrowCircleDown,
    LoginRounded,
    Logout,
    Shop,
} from "@mui/icons-material";
import { Button, InputBase } from "@mui/material";
import {
    Link,
    useLocation,
    useNavigate,
    useSearchParams,
} from "react-router-dom";

import "./TopHeader.scss";
import "./TopHeaderResponsive.scss";
import logo from "./../../../../assets/images/logo.png";
import { useAuthContext } from "context/AuthContext";
import { BottomHeaderMenu } from "./BottomHeader";

export default function MiddleHeader({ isFixed }: { isFixed: boolean }) {
    const navigate = useNavigate();

    const [searchKeyword, setSearchKeyword] = useState<string | null>(
        useSearchParams()[0].get("keywords")
    );

    const generalSearch = () => {
        if (searchKeyword) {
            navigate(`/search?keywords=${searchKeyword}`);
        }
    };

    return (
        <div className={"middleHeader hideWhenPrinting"}>
            <div className={"defaultContainerWrapper"}>
                <Link className={"middleHeaderDiv"} to="/">
                    <img
                        src={logo}
                        style={
                            {
                                // marginTop: isFixed ? "5px" : "45px",
                            }
                        }
                        alt={"logo"}
                        className={"responsiveImage"}
                    />
                </Link>
                <div className="rightMiddleHeaderSection">
                    <BottomHeaderMenu />
                    <SearchBar
                        onSearchKeyUp={(e) => {
                            setSearchKeyword(
                                (e.target as HTMLInputElement).value
                            );
                            if (e.keyCode === 13) {
                                generalSearch();
                            }
                        }}
                        generalSearch={generalSearch}
                    />
                    {/* <LoginButton showLogin={() => setShowingLoginContainer(true)} /> */}
                </div>
            </div>

            {/* <AccountPanel/> */}
        </div>
    );
}

function AccountPanel() {
    const [showingLoginContainer, setShowingLoginContainer] =
        useState<boolean>(false);
    let { isAuthenticated } = useAuthContext();

    useEffect(() => {
        const closeLoginDialog = () => {
            setShowingLoginContainer(false);
        };

        window.addEventListener("closeLoginDialog", closeLoginDialog);

        return () =>
            window.removeEventListener("closeLoginDialog", closeLoginDialog);
    }, []);

    return (
        <div
            className={"loginContainer"}
            onClick={() => setShowingLoginContainer(false)}
            style={
                showingLoginContainer
                    ? {
                          top: "70%",
                          opacity: 1,
                          visibility: "visible",
                      }
                    : {
                          top: "120%",
                          opacity: 0,
                          visibility: "hidden",
                      }
            }
            onMouseLeave={() => setShowingLoginContainer(false)}
        >
            <div className={"loginArrow"}></div>
            <div className={"loginInnerContainer"}>
                <div>Already Have an account?</div>
                <div>
                    <img
                        src={
                            "https://carsbase.com/photo/nissan/nissan-march-mk7-pic44771.jpg"
                        }
                        alt={"user"}
                    />
                </div>
                {!isAuthenticated ? <LoggedOutButtons /> : <LoggedInButtons />}
            </div>
        </div>
    );
}

function LoginButton({ showLogin }: { showLogin: () => void }) {
    let { isAuthenticated } = useAuthContext();

    return (
        <div className={"middleHeaderDiv"}>
            <div className={"login"} onMouseEnter={showLogin}>
                <Button
                    style={{
                        borderRadius: 0,
                        color: "white",
                        textTransform: "unset",
                    }}
                    size={"small"}
                >
                    <Person htmlColor={"white"} />
                    {!isAuthenticated ? "Login" : "Logged-In"}
                    <ArrowCircleDown style={{ marginLeft: "3px" }} />
                </Button>
            </div>
        </div>
    );
}

function SearchBar({
    generalSearch,
    onSearchKeyUp,
}: {
    onSearchKeyUp: React.KeyboardEventHandler<
        HTMLInputElement | HTMLTextAreaElement
    >;
    generalSearch: () => void;
}) {
    return (
        <div className={"middleHeaderDiv"}>
            <div className={"searchTextInput"}>
                <InputBase
                    defaultValue={useSearchParams()[0].get("keywords")}
                    fullWidth
                    style={{
                        paddingLeft: 15,
                        border: "none",
                        borderTopLeftRadius: 7,
                        borderBottomLeftRadius: 7,
                        height: 36,
                        lineHeight: 36,
                        backgroundColor: "#fff",
                    }}
                    onKeyUp={onSearchKeyUp}
                    placeholder={"Search Car Info"}
                    size={"small"}
                    className={"searchInputBar"}
                    inputProps={{
                        disableUnderline: true,
                        class: "searchBar",
                    }}
                />
            </div>

            <div className={"searchButton"}>
                <button
                    className={"searchCustomButton"}
                    onClick={generalSearch}
                    color={"primary"}
                >
                    <Search />
                </button>
            </div>
        </div>
    );
}

function LoggedOutButtons() {
    return (
        <>
            <div>
                <Link to="/login">
                    <Button fullWidth variant={"contained"}>
                        <LoginRounded style={{ marginRight: 10 }} /> Login
                    </Button>
                </Link>
            </div>
            <div>
                <Link to="/register">
                    <Button
                        fullWidth
                        style={{
                            backgroundColor: "red",
                            color: "white",
                            border: "unset",
                        }}
                        variant={"contained"}
                    >
                        Create New Account
                    </Button>
                </Link>
            </div>
        </>
    );
}

function LoggedInButtons() {
    return (
        <>
            <div>
                <Link to="/login?logout">
                    <Button
                        fullWidth
                        style={{
                            backgroundColor: "red",
                            color: "white",
                            border: "unset",
                        }}
                        variant={"contained"}
                    >
                        <Logout /> Log Out
                    </Button>
                </Link>
            </div>

            {/* <div>
                <a href={loginToken()}>
                    <Button
                        fullWidth
                        style={{
                            // backgroundColor:'red',
                            color: "white",
                            border: "unset",
                            marginTop: 10,
                            marginBottom: 10,
                        }}
                        variant={"contained"}
                    >
                        <Dashboard /> Dashboard
                    </Button>
                </a>
            </div> */}
        </>
    );
}
