import axios, { AxiosError, AxiosHeaders, AxiosResponse } from "axios";

import { isTokenExpired, useAuthContext } from "context/AuthContext";
import { API_ROOT } from "../../utils/apiConfig";
import { asyncTimeout, getExponentialBackoffTime } from "utils/utils";

export function useFetchData() {
    let { accessToken, refreshToken } = useAuthContext();

    const getToken = async () => {
        let token: null | string = accessToken || null;

        if (isTokenExpired(accessToken || null) && refreshToken) {
            token = await refreshToken();
        }

        return token;
    };

    const fetchData = async <T>(
        url: string,
        method: "GET" | "POST" | "DELETE" | "PATCH" | "PUT" = "GET",
        data: object | FormData | null = null,
        retryCount: number = 0
    ): Promise<AxiosResponse<T, any> | null> => {
        let token = await getToken();
        const headers = buildHeaders(token, data instanceof FormData);
        let body = buildBody(data);

        return await handleRetries(
            () => makeRequest<T>(url, method, body, headers),
            retryCount
        );
    };

    return fetchData;
}

function buildHeaders(token: string | null, multipartForm: boolean) {
    const headers: HeadersInit = {};

    if (!multipartForm) {
        headers["Content-Type"] = "application/json";
    }

    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    return new AxiosHeaders(headers);
}

function buildBody(data: object | FormData | null) {
    if (data instanceof FormData) {
        return data;
    } else if (data) {
        return JSON.stringify(data);
    } else {
        return null;
    }
}

async function makeRequest<T>(
    url: string,
    method: "GET" | "POST" | "DELETE" | "PATCH" | "PUT",
    body: FormData | string | null,
    headers: AxiosHeaders
) {
    switch (method) {
        case "GET":
            return await axios.get<T>(API_ROOT + url, { headers });
        case "PUT":
            return await axios.put<T>(API_ROOT + url, body, { headers });
        case "POST":
            return await axios.post<T>(API_ROOT + url, body, { headers });
        case "PATCH":
            return await axios.patch<T>(API_ROOT + url, body, { headers });
        case "DELETE":
            return await axios.delete<T>(API_ROOT + url, { headers });
        default:
            throw new AxiosError("Invalid HTTP method");
    }
}

async function handleRetries<T>(
    performRequest: () => Promise<AxiosResponse<T> | null>,
    retryCount: number
) {
    let iterationsPerformed = 0;
    let response: AxiosResponse<T, any> | null = null;

    do {
        try {
            response = await performRequest();
        } catch (error: any) {
            // Handle network errors or other issues
            console.error("API request error:", error);
            response = (error.response || error) as AxiosResponse<T>;
        }

        iterationsPerformed++;

        if (
            iterationsPerformed <= retryCount &&
            (!response || response.status >= 300)
        ) {
            await asyncTimeout(
                getExponentialBackoffTime(iterationsPerformed - 1)
            );
        }
    } while (
        iterationsPerformed <= retryCount &&
        (!response || response.status >= 300)
    );

    return response;
}
