export const BACKEND_URL = "https://api.carbellmotors.com";
export const API_ROOT = BACKEND_URL + "/api/v1";

export const apiRoutes = {
    // Users
    CUSTOMER_PROFILES: "/users/customer-profiles/",
    CUSTOMER_PROFILE: (id: number | string) =>
        `/users/customer-profiles/${id}/`,
    CUSTOMERS: "/users/customers/",
    CUSTOMER: (id: number | string) => `/users/customers/${id}/`,
    LOGIN: "/users/token/",
    REFRESH_TOKEN: "/users/token/refresh/",

    // Other endpoints
    MANUFACTURERS: "/vehicles/manufacturers/",
    MANUFACTURER: (id: number | string) => `/vehicles/manufacturers/${id}/`,
    MODELS: "/vehicles/models/",
    MODEL: (id: number | string) => `/vehicles/models/${id}/`,
    MANUFACTURERS_WITH_COUNT: "/vehicles/manufacturers-with-vehicle-count/",
    CATEGORIES: "/vehicles/vehicle-categories/",
    CATEGORY: (id: number | string) => `/vehicles/vehicle-categories/${id}`,
    CATEGORIES_WITH_COUNT: "/vehicles/vehicle-category-count",
    FEATURES: "/vehicles/vehicle-features/",
    FEATURE: (id: number | string) => `/vehicles/vehicle-features/${id}/`,
    PRICE_RANGES: "/vehicles/price-ranges/",
    VEHICLE_IMAGES: "/vehicles/vehicleimages/",
    VEHICLE_IMAGE: (id: number | string) => `/vehicles/vehicleimages/${id}/`,
    ALL_VEHICLES: "/vehicles/vehicles/",
    VEHICLE: (id: number | string) => `/vehicles/vehicles/${id}/`,
    ALL_SPECS: "/vehicles/vehiclespecs/",
    SPECS: (id: number | string) => `/vehicles/vehiclespecs/${id}/`,
    PROMOTIONAL_CATEGORIES: "/vehicles/promotional-categories/",
    PROMOTIONAL_CATEGORY: (id: number | string) =>
        `/vehicles/promotional-categories/${id}`,
    CREATE_INQUIRY: "/vehicles/create_inquiry/",
    CONTACT_US: "/vehicles/contactus/",
    DELIVERY_PORTS: "/vehicles/delivery-ports/",
    DELIVERY_PORT: (id: number | string) => `/vehicles/delivery-ports/${id}/`,
    COLORS: "/vehicles/colors/",
    UNIQUE_YEARS: "/vehicles/unique-years/",
    COUNTRIES: "/vehicles/countries/",
    COUNTRY: (id: number | string) => `/vehicles/countries/${id}/`,
    HOMEPAGE_BANNER_ADS: "/vehicles/homepage-banner-adverts/",
};
