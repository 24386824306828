import { SearchFilters } from "components/UI/molecules/searchSection/SearchSection";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { BACKEND_URL } from "./apiConfig";
import { Vehicle } from "types/shared";

export function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

export function isNumeric(number: string) {
    var numbers = /^[0-9]+$/;
    if (number.trim().match(numbers)) {
        return true;
    }
    return false;
}

export function isEmail(email: string) {
    var format = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return format.test(email);
}

/**
 * Capitalizes the first letter of each word
 *
 * ---
 * @param words A string of words
 * @returns
 */
export function sentenceCase(words: string) {
    try {
        return words
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    } catch (e) {
        return words;
    }
}

export function filtersToSearchParameters(searchFilters: SearchFilters) {
    let urlParts: string[] = [];

    let fields = {
        carMake: "manufacturer",
        model: "model",
        vehicleType: "category",
        engineCc: "engine_cc",
        vehicleColor: "vehicle_color",
        vehicleCountry: "country",
        vehicleFuelType: "fuel_type",
        vehicleDriveType: "transmission_type",
        vehicleYearOfMake: "year_of_make",
    };

    for (let field of Object.keys(fields) as Array<keyof SearchFilters>) {
        let fieldValue = searchFilters[field];
        if (typeof fieldValue === "string" && fieldValue.trim() !== "") {
            urlParts.push(`${fields[field]}=${searchFilters[field]}`);
        }
    }

    return urlParts.join("&");
}

export function appendBaseUrlToImage(imageUrl: string) {
    const HTTP_REGEX = /^https?:\/\//;
    return HTTP_REGEX.test(imageUrl) ? imageUrl : BACKEND_URL + imageUrl;
}

export function getDiscountedPrice(vehicle: Vehicle) {
    return vehicle.discount_percentage && vehicle.discount_percentage > 0
        ? (parseInt(vehicle.price as string) *
              (100 - vehicle.discount_percentage)) /
              100
        : parseInt(vehicle.price as string);
}

/**
 * Similar to `setTimeout`, but allows you to `await`
 * the timeout in async functionss
 */
export async function asyncTimeout(milliseconds: number) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

/**
 * Based on:
 * https://developers.google.com/analytics/devguides/reporting/core/v3/errors#backoff
 *
 * @param currentIteration The current iteration of the retry operation
 * @returns Backoff time in milliseconds
 */
export function getExponentialBackoffTime(
    currentIteration: number,
    baseDelayMs: number = 1000
) {
    return (2 ** currentIteration + Math.random()) * baseDelayMs;
}

export function leftpad(str: string, length: number, padChar = " "): string {
    const paddingLength = length - str.length;
    if (paddingLength <= 0) {
        return str;
    }
    const padding = padChar.repeat(paddingLength);
    return padding + str;
}
