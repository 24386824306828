import React, { Component } from "react";

import "./AppHeader.scss";
import TopHeader from "../../molecules/header/TopHeader";
import MiddleHeader from "../../molecules/header/MiddleHeader";
import BottomHeader from "../../molecules/header/BottomHeader";

type AppHeaderState = {
    isScrolled: boolean;
    headerEffect: string | null;
};

export default class AppHeader extends Component<{}, AppHeaderState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            isScrolled: false,
            headerEffect: null,
        };
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll = () => {
        if (window.scrollY > 200 && !this.state.isScrolled) {
            this.setState({ isScrolled: true });
            setTimeout(() => {
                this.setState({
                    headerEffect: "headerEffect",
                });
            }, 200);
        } else if (window.scrollY === 0 && this.state.isScrolled) {
            this.setState({ isScrolled: false });
            setTimeout(() => {
                this.setState({
                    headerEffect: null,
                });
            }, 200);
        }
    };

    render() {
        return (
            <div
                className={
                    !this.state.isScrolled
                        ? "appHeader"
                        : "fixHeader " + this.state.headerEffect
                }
            >
                <TopHeader contact={"+265 881 102 326 / +265 881 102 327"} />
                {!this.state.isScrolled ? (
                    <>
                        <MiddleHeader isFixed={this.state.isScrolled} />
                        {/* <BottomHeader /> */}
                        {/* <HeaderEndSection/> */}
                    </>
                ) : (
                    <MiddleHeader isFixed={this.state.isScrolled} />
                )}
            </div>
        );
    }
}
