import React from "react";
import { Link } from "react-router-dom";
import { useDocumentTitle } from "@mantine/hooks";

import styles from "./NotFound.module.scss";
import notFoundImage from "assets/images/404.png";

export default function NotFound() {
    useDocumentTitle("404 - Page Not Found");

    return (
        <div className={styles.notFoundContainer}>
            <div className={styles.notFoundContent}>
                <img src={notFoundImage} alt="" />
                <h1>404 - Page not found</h1>
                <p>
                    There was nothing found at this url. You may go back to{" "}
                    <Link to={"/"}>the homepage</Link>
                </p>
            </div>
        </div>
    );
}
