import React from "react";
import { Page, FacebookProvider } from "react-facebook";

export const FacebookPage = () => {
    return (
        <FacebookProvider appId="carbell-motors">
            <Page
                href="https://www.facebook.com/carbellmalawi?mibextid=ZbWKwL"
                tabs="timeline"
            />
        </FacebookProvider>
    );
};
