import React, { Component } from "react";
import { Facebook, Instagram, Twitter, WhatsApp } from "@mui/icons-material";
import { Link } from "react-router-dom";

import "./AppFooter.scss";
import logo from "../../../../assets/images/logo.png";
import { apiRoutes } from "utils/apiConfig";
import { useApiData } from "Hooks/ApiHooks/useApiData";
import { PromotionalCategory } from "types/shared";
import { useAuthContext } from "context/AuthContext";

export default function AppFooter() {
    let { isAuthenticated } = useAuthContext();
    let { data: promotionalCategories } = useApiData<
        PromotionalCategory[] | null
    >(apiRoutes.PROMOTIONAL_CATEGORIES, null);

    return (
        <>
            <div className={"appFooter hideWhenPrinting"}>
                <div className={"appFooterDiv"}>
                    <div className={"footer-logo-div"}>
                        <Link to="/">
                            <img
                                src={logo}
                                alt={"logo"}
                                className={"responsiveImage"}
                            />
                        </Link>
                    </div>
                    {/*<div className={"footer-info-div"}>*/}
                    {/*    <p>Carbell Building,</p>*/}
                    {/*    <p>2-65-3 Ikebukuro, Toshima-Ku,</p>*/}
                    {/*    <p>Lilongwe, Malawi</p>*/}
                    {/*    <p>TEL: +81-3-5960-0270</p>*/}
                    {/*    <p>FAX: +81-3-5960-0271</p>*/}
                    {/*</div>*/}
                </div>

                {promotionalCategories &&
                    promotionalCategories instanceof Array &&
                    promotionalCategories.length > 0 && (
                        <div className={"appFooterDiv"}>
                            <div className={"appFooterHeader"}>Our Stock</div>
                            <div className={"appFooterBody"}>
                                <ul>
                                    {promotionalCategories.map((element) => (
                                        <Link
                                            to={`/search?promotional_categories=${element.id}`}
                                        >
                                            <li>{element.name}</li>
                                        </Link>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}

                <div className={"appFooterDiv"}>
                    <div className={"appFooterHeader"}>
                        About Carbell-Motors
                    </div>
                    <div className={"appFooterBody"}>
                        <ul>
                            <Link to="/about-us">
                                <li>About Us</li>
                            </Link>
                            <Link to="/contact">
                                <li>Contact Us</li>
                            </Link>
                            {/* <Link to="/how-to-buy">
                                <li>How to Buy</li>
                            </Link> */}
                            {/* {!isAuthenticated ? (
                                <Link to="/login">
                                    <li>Login</li>
                                </Link>
                            ) : (
                                <Link to="/login?=logout">
                                    <li>Logout</li>
                                </Link>
                            )} */}

                            {/*<li>How to Request Quotation</li>*/}
                            {/*<li>How to Request Invoice</li>*/}
                            {/*<li>How to Pay</li>*/}
                        </ul>
                    </div>
                </div>

                <div className={"appFooterDiv"}>
                    <div className={"appFooterHeader"}>Contact Us</div>
                    <div className={"appFooterBody"}>
                        <ul>
                            <li>Carbell Building,</li>
                            <li>2-65-3 Ikebukuro, Toshima-Ku,</li>
                            <li>Lilongwe, Malawi</li>
                            <li>TEL: +81-3-5960-0270</li>
                            <li>FAX: +81-3-5960-0271</li>
                            <li>
                                <a
                                    target="_blank"
                                    href="https://www.facebook.com/carbellmalawi?mibextid=ZbWKwL"
                                    rel="noreferrer"
                                >
                                    <div>
                                        <Facebook />
                                    </div>
                                </a>
                                <a
                                    target="_blank"
                                    href="https://twitter.com/CarbellM?t=dBdu8j4EI2GCcJg2XfK7tQ&s=0"
                                    rel="noreferrer"
                                >
                                    <div>
                                        <Twitter />
                                    </div>
                                </a>
                                {/* <a 
                                        target="_blank" 
                                        href="https://www.facebook.com/carbellmalawi?mibextid=ZbWKwL" 
                                        rel="noreferrer" 
                                    >
                                        <div>
                                            <Instagram/>
                                        </div>
                                    </a> */}
                                <a
                                    target="_blank"
                                    href="https://wa.me/265998562786"
                                    rel="noreferrer"
                                >
                                    <div>
                                        <WhatsApp />
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* <div className={"appFooterBottomBar hideWhenPrinting"}>
                Developed By
                <span style={{ color: "green !important;", marginLeft: 8 }}>
                    Cool Enterprises Limited
                </span>
            </div> */}
        </>
    );
}
