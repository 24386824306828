import { useTheme } from "@mui/material";
import React from "react";
import { ThreeDots } from "react-loader-spinner";

export const Preloader = () => {
    const theme = useTheme();

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignContent: "center",
            }}
        >
            <ThreeDots
                visible={true}
                height="50"
                width="50"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                color={theme.palette.primary.main}
            />
            {/*<ClipLoader*/}
            {/*    color={'#9a451b'}*/}
            {/*    loading={true}*/}
            {/*    // cssOverride={override}*/}
            {/*    size={70}*/}
            {/*    aria-label="Loading Spinner"*/}
            {/*    data-testid="loader"*/}
            {/*/>*/}
        </div>
    );
};
