import React from "react";
import {
    FlagCircleOutlined,
    LocalGasStationSharp,
    MonetizationOn,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import "car-makes-icons/dist/style.css";

import "./CategoryListings.scss";
import { sentenceCase } from "utils/utils";

export const SectionLabel = ({ labelName }: { labelName: string }) => {
    return <div className={"sectionLabel"}>{labelName}</div>;
};

export const CarMake = ({
    make,
    id,
    defaultMake,
    icon,
    count,
}: {
    make: string;
    id: number;
    defaultMake?: string;
    icon?: IconProp;
    count: number;
}) => {
    let myMake = defaultMake || make;

    return (
        <Link
            to={!icon ? `/search?manufacturer=${id}` : `/search?category=${id}`}
        >
            <ul className={"carMakeList"} id={id.toString()}>
                <li>
                    <div>
                        {!icon ? (
                            <i className={`car-${myMake.toLowerCase()}`} />
                        ) : (
                            <FontAwesomeIcon icon={icon} />
                        )}
                    </div>
                    <div>{make.toUpperCase()}</div>
                    <div>{count}</div>
                </li>
            </ul>
        </Link>
    );
};

export const ShopByPrice = ({ amountRange }: { amountRange: string }) => {
    return (
        <Link to={`/search?price_range=${amountRange}`}>
            <ul className={"priceList"}>
                <li>
                    <div>
                        <MonetizationOn />
                    </div>
                    <div>{amountRange}</div>
                </li>
            </ul>
        </Link>
    );
};

export const OtherCategoriesCard = ({
    categoryName,
    id,
}: {
    categoryName: string;
    id: string | number;
}) => {
    return (
        <Link to={`/search?fuel_type=${id}`}>
            <ul className={"priceList"}>
                <li>
                    <div>
                        <LocalGasStationSharp />
                    </div>
                    <div>{categoryName}</div>
                </li>
            </ul>
        </Link>
    );
};

export const VehiclesInStockCard = ({
    country,
}: {
    country: { country_id: string | number; country_name: string };
}) => {
    const { country_id, country_name } = country;
    return (
        <Link to={`/search?country=${country_id}`}>
            <ul className={"priceList"}>
                <li>
                    <div>
                        <FlagCircleOutlined />
                    </div>
                    <div>{sentenceCase(country_name.toLowerCase())}</div>
                </li>
            </ul>
        </Link>
    );
};
