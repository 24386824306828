import { DefinedUseQueryResult, useQuery } from "@tanstack/react-query";
import { useFetchData } from "./useFetchData";

export function useApiData<T>(
    url: string,
    initialData: T,
    dependencies: Array<any> = []
): DefinedUseQueryResult<T> {
    let fetchData = useFetchData();

    return useQuery<T>({
        queryKey: [url, ...dependencies.map(String)],
        initialData,
        queryFn: () =>
            fetchData<T>(url).then((response) => response?.data || initialData),
    });
}
