import React, { ReactNode, Suspense, useCallback } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import "./AppBody.scss";
import { ScrollToTop, filtersToSearchParameters } from "utils/utils";
import { Preloader } from "components/UI/atoms/Preloader";
import { SearchFilters } from "../../molecules/searchSection/SearchSection";
import NotFound from "components/pages/404/NotFound";
import { MainPageWrapper, MainPageWrapperProps } from "./MainPageWrapper";
import { apiRoutes } from "utils/apiConfig";
import { useApiData } from "Hooks/ApiHooks/useApiData";
import {
    ManufacturerWithCount,
    Model,
    PromotionalCategory,
    VehicleCategoryWithCount,
    VehicleFeature,
} from "types/shared";

const HomePage = React.lazy(() => import("../../../pages/home/HomePage"));
const Ratings = React.lazy(() => import("../../../pages/ratings/Ratings"));
const SearchSection = React.lazy(
    () => import("../../molecules/searchSection/SearchSection")
);
const SearchPage = React.lazy(
    () => import("../../../pages/searchPage/SearchPage")
);
const Quotation = React.lazy(
    () => import("../../../pages/quotation/Quotation")
);
const About = React.lazy(() => import("../../../pages/about/About"));
const Details = React.lazy(() => import("../../../pages/details/Details"));
const UnpublishedVehicles = React.lazy(
    () => import("../../../pages/unpublished/UnpublishedVehicles")
);
const UploadVehicle = React.lazy(
    () => import("../../../pages/uploadVehicle/UploadVehicle")
);
const EditVehicle = React.lazy(
    () => import("../../../pages/uploadVehicle/EditVehicle")
);
const LoginPage = React.lazy(() => import("../../../pages/login/Login"));
const HowToBuy = React.lazy(() => import("../../../pages/howtobuy/HowToBuy"));
const Register = React.lazy(() => import("../../../pages/register/Register"));
const Contactus = React.lazy(
    () => import("../../../pages/contactus/ContactUs")
);

export type SearchParameterOption = {
    id: string | number;
    name: string;
};

export type AdvertData = {
    image: string;
    label: string;
};

export type AvailableCountry = {
    country_id: string | number;
    country_name: string;
};

export type ShopByTypeObject = {
    vehicle_type_id: string;
    vehicle_type_name: string;
    count: string;
};

export type CarMakesObject = {
    vehicle_make_id: string;
    vehicle_make_name: string;
    vehicle_make_date_created: string;
    count: string;
};

export default function AppBody() {
    let navigate = useNavigate();

    type PriceRangesReturnType = {
        price_ranges: Array<[number, number]>;
    };

    let { data: priceRanges } = useApiData<PriceRangesReturnType | null>(
        apiRoutes.PRICE_RANGES,
        null
    );

    let { data: vehicleFeatures } = useApiData<VehicleFeature[] | null>(
        apiRoutes.FEATURES,
        null
    );

    let { data: carManufacturers } = useApiData<ManufacturerWithCount[] | null>(
        apiRoutes.MANUFACTURERS_WITH_COUNT,
        null
    );

    let { data: models } = useApiData<Model[] | null>(apiRoutes.MODELS, null);

    const { data: shopByType } = useApiData<VehicleCategoryWithCount[] | null>(
        apiRoutes.CATEGORIES_WITH_COUNT,
        null
    );

    let { data: promotionalCategories } = useApiData<
        PromotionalCategory[] | null
    >(apiRoutes.PROMOTIONAL_CATEGORIES, null);

    const searchEngine = useCallback(
        (data: SearchFilters) => {
            navigate(`/search?${filtersToSearchParameters(data)}`);
        },
        [navigate]
    );

    const wrapperProps: Omit<MainPageWrapperProps, "children"> = {
        carManufacturers,
        priceRanges: priceRanges?.price_ranges || null,
        shopByType,
        otherCategories: [
            { name: "Petrol", id: 1 },
            { name: "Diesel", id: 2 },
            { name: "Electric", id: 3 },
        ],
    };

    return (
        <div className={"defaultContainerWrapper"}>
            <div className={"appMainBody"}>
                <ScrollToTop />
                <Routes>
                    <Route
                        index
                        element={
                            <MainPageWrapper {...wrapperProps}>
                                <HomePage
                                    onSearch={searchEngine}
                                    manufacturers={carManufacturers}
                                    vehicleCategories={shopByType}
                                    promotionalCategories={
                                        promotionalCategories
                                    }
                                    models={models}
                                />
                            </MainPageWrapper>
                        }
                    />
                    <Route
                        path={"search"}
                        element={
                            <MainPageWrapper {...wrapperProps}>
                                <SearchPage
                                    allFeatures={vehicleFeatures}
                                    manufacturers={carManufacturers}
                                    vehicleCategories={shopByType}
                                    models={models}
                                />
                            </MainPageWrapper>
                        }
                    />
                    <Route
                        path={"mobile-search-engine"}
                        element={
                            <MainPageWrapper {...wrapperProps}>
                                <SearchSection
                                    showOnMobile={true}
                                    onSearch={searchEngine}
                                    manufacturers={carManufacturers}
                                    vehicleCategories={shopByType}
                                    vehicleModels={models}
                                />
                            </MainPageWrapper>
                        }
                    />
                    <Route
                        path={"details/:id/:name/:description"}
                        element={withSuspense(
                            <Details allFeatures={vehicleFeatures} />
                        )}
                    />
                    <Route
                        path={"unpublished"}
                        element={withSuspense(<UnpublishedVehicles />)}
                    />
                    <Route
                        path={"upload-vehicle"}
                        element={withSuspense(<UploadVehicle />)}
                    />
                    <Route
                        path={"edit-vehicle/:id"}
                        element={withSuspense(<EditVehicle />)}
                    />
                    <Route
                        path={"login"}
                        element={withSuspense(<LoginPage />)}
                    />
                    <Route
                        path={"register"}
                        element={withSuspense(<Register />)}
                    />
                    <Route
                        path={"about-us"}
                        element={withSuspense(<About />)}
                    />
                    <Route
                        path={"how-to-buy"}
                        element={withSuspense(<HowToBuy />)}
                    />
                    <Route
                        path={"contact"}
                        element={withSuspense(<Contactus />)}
                    />
                    <Route
                        path={"details/:id/:name/:description/buy"}
                        element={withSuspense(<Quotation />)}
                    />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </div>
        </div>
    );
}

export function withSuspense(component: ReactNode | ReactNode[]) {
    return <Suspense fallback={<Preloader />}>{component}</Suspense>;
}
