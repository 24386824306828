import React, { Component } from "react";
import {
    CarRentalSharp,
    Engineering,
    Settings,
    Shop,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

import "./TopHeader.scss";

export default class BottomHeader extends Component {
    constructor(props: {}) {
        super(props);
    }

    render() {
        return (
            <div className={"bottomHeader hideWhenPrinting"}>
                <BottomHeaderMenu />
            </div>
        );
    }
}

export function BottomHeaderMenu() {
    return (
        <div className={"bottomHeaderMenu"}>
            <ul>
                <li>
                    <Link to="/">
                        {/* <Shop
                        style={{
                            marginBottom: -5,
                        }} />{" "} */}
                        Home
                    </Link>
                </li>
                <li>
                    <Link to="/about-us">About Us</Link>
                </li>
                {/* <li>
                    <Link to="/how-to-buy">How to buy ?</Link>
                </li> */}
                <li>
                    <Link to="/contact">Contact Us</Link>
                </li>
            </ul>
        </div>
    );
}
